import clsx from 'clsx';
import React from 'react';
import styles from './container.module.less';

export interface ContainerProps extends React.PropsWithChildren {
  className?: string;
  style?: React.CSSProperties;
}

export function Container({ children, className, style }: ContainerProps) {
  return (
    <div
      style={style}
      className={clsx(
        'relative overflow-auto p-4 h-screen',
        styles.container,
        className,
      )}
    >
      {children}
    </div>
  );
}
